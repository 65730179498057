import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import Input from "../extra/Input";
import {
  addDeveloper,
  updateDeveloper,
} from "../../store/slice/developerSlice";
import {
  addCommunity,
  updateCommunity,
} from "../../store/slice/communitySlice";
import Multiselect from "multiselect-react-dropdown";
import { getApp } from "../../store/slice/notificationSlice";

const CommunityDialog = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { getAppData, notificationData } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();
  const [appData, setAppData] = useState([]);

  useEffect(() => {
    setAppData(getAppData);
  }, [getAppData]);

  useEffect(() => {
    dispatch(getApp());
  }, []);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newApps, setNewApps] = useState([]);

  const [error, setError] = useState({
    name: "",
    image: "",
    title: "",
  });

  useEffect(() => {
    if (dialogueData) {
      const defaultOptions = dialogueData?.selectedApps?.map((option) => ({
        _id: option?._id,
        name: option?.appName,
      }));

      console.log("defaultOptions", defaultOptions);

      const selectedImages = dialogueData?.fakeUserImages?.join(",");
      setImage(selectedImages);
      setTitle(dialogueData?.title);
      setName(dialogueData?.fakeUserNames?.join(","));
      setSelectedOptions(defaultOptions);
    }
  }, [dialogueData]);

  const handleSubmit = () => {
    if (!name || !image || !title || !newApps) {
      let error = {};
      if (!name) error.name = "Name is required";
      if (!title) error.title = "title is required";
      if (!image) error.image = "Fake user Image is required";
      if (!newApps) error.newApps = "Select App is required";
      return setError({ ...error });
    } else {
      const payloadApps = newApps.map((item) => item._id).join(",");
      const data = {
        fakeUserNames: name,
        fakeUserImages: image,
        title,
        selectedApps: payloadApps,
      };
      if (dialogueData) {
        const payload = { ...data, communityId: dialogueData?._id };
        dispatch(updateCommunity(payload));
      } else {
        dispatch(addCommunity(data));
      }
    }
    dispatch(closeDialog());
  };

  function onSelect(selectedList, selectedItem) {
    setNewApps(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    const updatedServices = selectedList?.filter(
      (item) => item.id !== removedItem.id
    );
    setNewApps(updatedServices);
  }

  const options = appData?.map((option) => ({
    name: option?.appName,
    _id: option?._id,
  }));

  return (
    <div className="DevDialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>Community Dialog</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row">
              <div className="col-12 mt-2 ">
                <div className="custom-input">
                  <label className="mb-2">Select Apps</label>
                </div>
                <Multiselect
                  options={options}
                  selectedValues={selectedOptions ? selectedOptions : []}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  className="cursor-pointer"
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Title`}
                  id={`title`}
                  type={`text`}
                  value={title}
                  placeholder={"Enter Title"}
                  errorMessage={error?.title && error?.title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Fake User name`}
                  type={`text`}
                  placeholder={"Enter Fake user name"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  errorMessage={error?.name && error?.name}
                  value={name}
                />
              </div>
              <div className="col-12 inputData">
                <div className="custom-input">
                  <label className="mb-2">Fake User Image</label>
                </div>

                <textarea
                  type={`text-area`}
                  placeholder={"Enter Fake user image"}
                  onChange={(e) => {
                    setImage(e.target.value);
                  }}
                  width={"100%"}
                  value={image}
                />
              </div>
            </div>
          </div>
          <p className="mt-3 text-danger">Note:- Image and name are saperate by comma(,)</p>
          <div className="row dialog-footer mt-3">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={() => dispatch(closeDialog())}
                style={{ marginRight: "10px" }}
                newClass="cancel-btn"
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass="submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityDialog;
