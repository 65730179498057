import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import gameReducer from "./slice/gameSlice";
import allAppReducer from "./slice/appSlice";
import dialogReducer from "./slice/dialogueSlice";
import movedAppReducer from "./slice/movedAppSlice";
import customAdSlice from "./slice/customAdSlice";
import moreAppSlice from "./slice/moreAppSlice";
import shortVideoSlice from "./slice/shortvideoSlice";
import liveStreamingVideoSlice from "./slice/liveStreamSlice";
import videoCallVideoSlice from "./slice/videoCallSlice";
import privateVideoSlice from "./slice/privateVideoSlice";
import testAdIdSlice from "./slice/testAdIdSlice";
import postSlice from "./slice/postSlice";
import developerSlice from "./slice/developerSlice";
import notificationSlice from "./slice/notificationSlice";
import hostSlice from "./slice/hostSlice";
import GiftReducer from "./slice/GiftSlice";
import bannerSlice from "./slice/bannerSlice";
import newMonetizationSlice from "./slice/newMonetizationSlice";
import communitySlice from "./slice/communitySlice";
import testSlice from "./slice/TestSlice"
import callPlanSlice from "./slice/callPlanSlice"


const store = configureStore({
  reducer: {
    admin: authReducer,
    game: gameReducer,
    app: allAppReducer,
    gift: GiftReducer,
    dialogue: dialogReducer,
    movedApp: movedAppReducer,
    customAd: customAdSlice,
    moreApp: moreAppSlice,
    hostData: hostSlice,
    shortVideo: shortVideoSlice,
    liveStreamVideo: liveStreamingVideoSlice,
    videoCallVideo: videoCallVideoSlice,
    privateVideo: privateVideoSlice,
    testId: testAdIdSlice,
    developer: developerSlice,
    post: postSlice,
    banner: bannerSlice,
    notification: notificationSlice,
    monetization:newMonetizationSlice,
    community:communitySlice,
    test:testSlice,
    callPlan:callPlanSlice
  },
});

export default store;
