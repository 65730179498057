import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { vipPlanUpdate, addVipPlan } from "../../store/slice/appSlice";
import Input from "../extra/Input";
import ToggleSwitch from "../extra/ToggleSwitch";
import Button from "../extra/Button";
import ReactSelect from "react-select";
import Textarea from "../extra/TextArea";
import { useLocation } from "react-router-dom";
import { baseURL } from "../utils/config";
import { addPlan, updatePlan } from "../../store/slice/callPlanSlice";

const CallPlanDialogue = (props) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);
    const [minCalls, setMinCalls] = useState(0);
    const [maxCalls, setMaxCalls] = useState(0);
    const [ratePerCall, setRatePerCall] = useState(0);


    const location = useLocation();
    const [error, setError] = useState({
        minCalls: "",
        maxCalls: "",
        ratePerCall: "",
    });

    const handleClose = () => {
        dispatch(closeDialog());
    };



    useEffect(() => {
        if (dialogueData) {
            setMaxCalls(dialogueData?.maxCalls);
            setMinCalls(dialogueData?.minCalls);
            setRatePerCall(dialogueData?.ratePerCall);
        }
    }, [dialogueData]);

    const handleSubmit = (e) => {
        if (
            (!minCalls ||
                !maxCalls ||
                !ratePerCall || 
                minCalls > maxCalls ||
                maxCalls < minCalls

            )
        ) {
            let error = {};
            if (!minCalls) error.minCalls = "Mincalls Is Required !";
            if (!maxCalls) error.maxCalls = "maxCalls  Is Required !";
            if (!ratePerCall) error.ratePerCall = "ratePerCall  Is Required !";
            if (minCalls > maxCalls) error.maxCalls = "maxCalls should be greater than minCalls";
            if (maxCalls < minCalls  ) error.maxCalls = "maxCalls should be greater than minCalls";

            return setError({ ...error });
        } else {
            const data = {
                minCalls,

                maxCalls,
                ratePerCall,
            }

            if (dialogueData) {
                
                const payload = {
                    appId: location?.state?.editAppData?._id,
                    callPlanId: dialogueData?._id,
                    data

                };
                dispatch(updatePlan(payload));
                dispatch(closeDialog());
            } else {
                const payload = {
                    appId: location?.state?.editAppData?._id,
                    minCalls,

                    maxCalls,
                    ratePerCall,
                };
                dispatch(addPlan(payload));
                dispatch(closeDialog());
            }
        }
    };

    return (
        <div className="vipPlan-edit">
            <div className="dialog">
                <div className="mainDiaogBox">
                    <div className="dialog-head">
                        <h4>{dialogueData ? "Update VipPlan" : "Create New VipPlan"}</h4>
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                    <form>
                        <div className="dialog-body">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Input
                                        label={`Min Calls`}
                                        id={`minCalls`}
                                        type={`text`}
                                        value={minCalls}
                                        errorMessage={error.minCalls && error.minCalls}
                                        onChange={(e) => {
                                            setMinCalls(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...error,
                                                    minCalls: `Min Calls Is Required`,
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    minCalls: "",
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        label={`Max Calls`}
                                        id={`maxCalls`}
                                        type={`number`}
                                        value={maxCalls}
                                        errorMessage={error.maxCalls && error.maxCalls}
                                        onChange={(e) => {
                                            setMaxCalls(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...error,
                                                    maxCalls: `Max Calls Is Required`,
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    maxCalls: "",
                                                });
                                            }
                                        }}
                                    />
                                </div>



                                <div className="col-12 col-md-6">
                                    <Input
                                        label={`Rate Per Call`}
                                        id={`ratePerCall`}
                                        type={`number`}
                                        value={ratePerCall}
                                        errorMessage={error.ratePerCall && error.ratePerCall}
                                        onChange={(e) => {
                                            setRatePerCall(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...error,
                                                    ratePerCall: `Rate Per Call Is Required`,
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    ratePerCall: "",
                                                });
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="row dialog-footer">
                            <div className="col-6"></div>
                            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                                <Button
                                    btnName={"Cancel"}
                                    onClick={handleClose}
                                    newClass={"cancel-btn"}
                                />
                                <Button
                                    btnName={"Submit"}
                                    type={"button"}
                                    style={{ marginLeft: "10px" }}
                                    onClick={handleSubmit}
                                    newClass={"submit-btn"}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default connect(null, { vipPlanUpdate, addVipPlan })(CallPlanDialogue);
