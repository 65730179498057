import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Input from "../extra/Input";
import {
  addDeveloper,
  updateDeveloper,
} from "../../store/slice/developerSlice";
import {
  addCommunity,
  communityMessage,
  updateCommunity,
} from "../../store/slice/communitySlice";
import Multiselect from "multiselect-react-dropdown";
import { getApp } from "../../store/slice/notificationSlice";

import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";

const CommunityMessage = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  const [type, setType] = useState("1");
  const [image, setImage] = useState();
  const [imageShow, setImageShow] = useState();

  const [message, setMessage] = useState("");

  const [error, setError] = useState({
    name: "",
    image: "",
    message: "",
  });

  useEffect(() => {
    if (dialogueData) {
    }
  }, [dialogueData]);

  const handleSubmit = () => {
    
    if (!type || (!image && !message)) {
      let error = {};
      if (!message && !image) error.message = "Message Or image is required";
      if (!message && !image) error.image = "Message Or image is required";

      return setError({ ...error });
    } else {
      let data;
      if (type == 1) {
        data = {
          message,
          messageType: 1,
          communityId: dialogueData?._id,
        };
        dispatch(communityMessage(data));
      }

      if (type == 2) {
        const formData = new FormData();
        formData.append("messageType", 2);
        formData.append("communityId", dialogueData?._id);
        formData.append("image", image);
        dispatch(communityMessage(formData));
      }
    }

  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleFileUpload = (e) => {
    setImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setImageShow(showLogo);

    if (!e.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  return (
    <div className="DevDialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>Community Message</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row">
              <div className="col-12 mt-3">
                <h6 className="labelInput">Choose Message Type</h6>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue="1"
                    name="radio-buttons-group"
                    value={type}
                    onChange={(e) => handleTypeChange(e)}
                    className="d-flex"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Message"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Image"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {type === "1" && (
                <div className="col-12">
                  <Input
                    label={`Message`}
                    id={`message`}
                    type={`text`}
                    value={message}
                    placeholder={"Enter Message"}
                    errorMessage={error?.message && error?.message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
              )}
              {type === "2" && (
                <div className="col-12  image-input mt-2">
                  <h6 className="labelInput">Image</h6>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/gif, image/jpeg, image/png"
                    className="form-input"
                    onChange={handleFileUpload}
                  />
                  {error?.image && (
                    <p className="errorMessage">{error?.image}</p>
                  )}
                  {imageShow && (
                    <img
                      src={imageShow}
                      height={100}
                      width={100}
                      className="ms-2"
                      style={{
                        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.35)",
                        borderRadius: "6px",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row dialog-footer mt-3">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={() => dispatch(closeDialog())}
                style={{ marginRight: "10px" }}
                newClass="cancel-btn"
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass="submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityMessage;
