/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState,useRef } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extra/Button";
import ToggleSwitch from "../extra/ToggleSwitch";
import dayjs from "dayjs";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { openDialog } from "../../store/slice/dialogueSlice";
import {
  deleteShortVideo,
  getAllShortVideo,
} from "../../store/slice/shortvideoSlice";
import ShortVideoDialog from "../dialogComponent/ShortVideoDialog";
import { isSkeleton } from "../utils/allSelector";

const Row = () => (
  <div className="row">
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
  </div>
);
const ShortVideo = () => {
  const videoRef = useRef(null);
  const [data, setData] = useState([]);
  const [tableValues, setTableValues] = useState([])
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const [skeleton, setSkeleton] = useState()
  const numberOfRows = 10;
  const { shortVideo, total } = useSelector((state) => state.shortVideo);
  const navigate = useNavigate();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const isSkeletonLoader = useSelector(isSkeleton);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    dispatch(getAllShortVideo(payload));
  }, [start, limit]);

  useEffect(() => {
    const renderedRows = [...Array(numberOfRows)]?.map((e, i) => (
      <div>
        <Row />
      </div>
    ));
    setSkeleton(renderedRows)
  }, [numberOfRows])

  useEffect(() => {
    if (shortVideo) {
      // const transformedData = shortVideo?.flatMap((video) =>
      //   video?.link?.map((link) => ({
      //     _id: video?._id,
      //     link,
      //     createdAt: video?.createdAt,
      //     updatedAt: video?.updatedAt,
      //   }))
      // );
      setData(shortVideo);
      setTableValues(shortVideo);
    }
  }, [shortVideo]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleDelete = (id) => {
    dispatch(deleteShortVideo(id));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="shortVideo">
      <Title title="Short Video" />
      {dialogue && dialogueType === "shortVideo" && <ShortVideoDialog />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total Short Videos: {total}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={tableValues}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center" >
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("shortVideo")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        {/* <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        /> */}
        <div className="simple-table">
          <table>
            <thead>
              <tr>
                <th>
                  <span>Index</span>
                </th>
                <th>
                  <span>Video</span>
                </th>
                <th>
                  <span>Link</span>
                </th>
                <th>
                  <span>Gender</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
                <th>
                  <span>Created At</span>
                </th>
              </tr>
            </thead>
            {
              isSkeletonLoader === true ? (
                <tbody>
                  <tr>
                    <td>
                      {skeleton}
                    </td>
                    <td>
                      {skeleton}
                    </td>
                    <td>
                      {skeleton}
                    </td>
                    <td>
                      {skeleton}
                    </td>
                    <td>
                      {skeleton}
                    </td>
                    <td>
                      {skeleton}
                    </td>
                  </tr>
                </tbody>
              ) :
                (
                  <tbody>
                    {data?.length > 0 &&
                      data?.map((item, index) => {
                        const adjustedIndex = index + (start - 1) * limit + 1;
                        return (
                          <tr>
                            <td><span>{adjustedIndex}</span></td>

                            <td>
                              <video
                                ref={videoRef}
                                src={item?.link}
                                height="120"
                                width="120"
                                controls={false} // Hide default controls if you want
                                style={{ borderRadius: "10px", cursor: "pointer" }}
                                onClick={(e) => {
                                  if (e.target.paused) {
                                    e.target.play(); // Play video on click if paused
                                  } else {
                                    e.target.pause(); // Pause video on click if playing
                                  }
                                }}
                              />
                            </td>
                            <td><span>{item?.link}</span></td>
                            <td>
                              {item?.gender === "male" ? (
                                <Button
                                  btnName={"Male"}
                                  style={{ marginRight: "10px" }}
                                  newClass={`male-btn`}
                                />
                              ) : item?.gender === "female" ?
                                (
                                  <Button
                                    btnName={"Female"}
                                    style={{ marginRight: "10px" }}
                                    newClass={`female-btn m-auto`}
                                  />)
                                : <div className="text-center">-</div>}
                            </td>
                            <td >
                              <Button
                                btnIcon={<DeleteRoundedIcon />}
                                onClick={() => handleDelete(item?._id)}
                                newClass={"deleteBtn"}
                              />
                            </td>
                            <td><span className="text-nowrap">{dayjs(item?.createdAt)?.format("DD MMM YYYY")}</span></td>
                          </tr>
                        );
                      })}
                  </tbody>
                )
            }
          </table>
        </div>
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default ShortVideo;
