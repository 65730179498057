import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import {
  getApp,
  sendNotification,
  editNotification,
  getNotificationData,
} from "../../store/slice/notificationSlice";
import Input from "../extra/Input";
import UpdateIcon from "@mui/icons-material/Update";
import { connect, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { Box, Tabs, Tab, TextField, Autocomplete } from "@mui/material";
import Button from "../extra/Button";


const NotificationPage = (props) => {
  const { getAppData, notificationData } = useSelector(
    (state) => state.notification
  );
  const [appData, setAppData] = useState([]);
  const [error, setError] = useState({
    immediatelyTitle: "",
    immediatelyDescription: "",
    immediatelyLink: "",
    immediatelyAppSelect: "",
  });
  const [immediatelyInput, setImmediatelyInput] = useState({
    title: "",
    description: "",
    link: "",
    appSelect: "",
  });
  const [appSelect, setAppSelect] = useState("");

  const [specificTimeInput, setSpecificTimeInput] = useState({
    title: [],
    description: [],
    link: [],
  });
  const [specificTimeError, setSpecificTimeError] = useState({
    specificTimeTitle: "",
    specificTimeDescription: "",
    specificTimeLink: "",
    specificTimeAppSelect: "",
    specificTimeTime: "",
  });

  const [valueSendNoti, setValueSendNoti] = useState([]);
  const [inputValueSendNoti, setInputValueSendNoti] = useState("");
  const [specificTimeShowImage, setSpecificTimeShowImage] = useState();
  const [timeSendNoti, setTimeSendNoti] = useState("");
  const [specificNotiError, setSpecificNotiError] = useState({
    specificNotiApp: "",
    specificNotiTime: "",
  });

  useEffect(() => {
    setAppData(getAppData);
  }, [getAppData]);

  useEffect(() => {
    props.getApp();
    props.getNotificationData();
  }, []);

  // immediatelyInput
  const handleChangeSelectApp = (e, value) => {
    const selectedIds = value?.map((option) => option?._id);
    setAppSelect(selectedIds);

    if (!selectedIds) {
      return setError({
        ...error,
        immediatelyAppSelect: `Select App Is Required !`,
      });
    } else {
      return setError({
        ...error,
        immediatelyAppSelect: "",
      });
    }
  };

  const handleImmediatelyInputChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setImmediatelyInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [errorValue]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [errorValue]: "",
      });
    }
  };
  const handleSubmit = () => {
    const { title, description, link } = immediatelyInput;

    if (!title || !description || !link || appSelect?.length === 0) {
      let error = {};
      if (!title) error.immediatelyTitle = "Title Is Required !";
      if (!description)
        error.immediatelyDescription = "Description Is Required !";
      if (!link) error.immediatelyLink = "Image Is Required !";
      if (appSelect?.length === 0)
        error.immediatelyAppSelect = "Select App Is Required !";
      return setError({ ...error });
    } else {
      let data = {
        title: title,
        discreption: description,
        image: link,
        appId: appSelect ? appSelect?.join(",") : appSelect,
      };

      const payload = {
        data: data,
        appId: appSelect ? appSelect?.join(",") : appSelect,
      };
      props.sendNotification(payload);
    }
  };

  // specificTime

  useEffect(() => {
    setSpecificTimeInput({
      title: notificationData?.title
        ? notificationData?.title?.join(",")
        : notificationData?.title,
      description: notificationData?.discreption
        ? notificationData?.discreption?.join(",")
        : notificationData?.discreption,
      link: notificationData?.image
        ? notificationData?.image?.join(",")
        : notificationData?.image,
    });
  }, [notificationData]);

  const handleSpecificTimeChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setSpecificTimeInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setSpecificTimeError({
        ...error,
        [errorValue]: errorMessage,
      });
    } else {
      setSpecificTimeError({
        ...error,
        [errorValue]: "",
      });
    }
  };

  const handleSubmitpecificTime = () => {
    const { title, description, link } = specificTimeInput;
    if (!title || !description || !link) {
      let error = {};
      if (!title) error.specificTimeTitle = "Title Is Required !";
      if (!description)
        error.specificTimeDescription = "Description Is Required !";
      if (!link) error.specificTimeLink = "Image Is Required !";
      return setSpecificTimeError({ ...error });
    } else {
      let data = {
        title: title,
        discreption: description,
        image: link,
      };

      const payload = {
        data: data,
        id: notificationData?._id,
      };
      props.editNotification(payload);
    }
  };
  // specific Time send notification
  const handleSpecificNotification = () => {
    const valueSpecificGetId = valueSendNoti?.map((item) => item?._id);
    if (!timeSendNoti || valueSendNoti?.length === 0) {
      let error = {};
      if (!timeSendNoti) error.specificNotiTime = "Title Is Required !";
      if (valueSendNoti?.length === 0)
        error.specificNotiTime = "Select App Is Required !";
      return setSpecificNotiError({ ...error });
    } else {
      let data = {
        specificTime: timeSendNoti,
        appId: valueSpecificGetId?.join(","),
      };

      const payload = {
        data: data,
        appId: valueSpecificGetId?.join(","),
      };
      props.sendNotification(payload);
    }
  };

  return (
    <div className="notificationPage">
      <Title title={"Notification"} />
      <div className="notification-body">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <div className="notification-pageBox">
              <form>
                <h6>Immediately Notification</h6>
                <div className="row">
                  <div className="col-12">
                    <Input
                      label={`Title`}
                      id={`title`}
                      type={`text`}
                      name={"title"}
                      value={immediatelyInput.title}
                      errorMessage={
                        error.immediatelyTitle && error.immediatelyTitle
                      }
                      onChange={(e) =>
                        handleImmediatelyInputChange(
                          e,
                          "immediatelyTitle",
                          "Title Is Required !"
                        )
                      }
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={`Description`}
                      id={`description`}
                      type={`text`}
                      name={"description"}
                      value={immediatelyInput.description}
                      errorMessage={
                        error.immediatelyDescription &&
                        error.immediatelyDescription
                      }
                      onChange={(e) =>
                        handleImmediatelyInputChange(
                          e,
                          "immediatelyDescription",
                          "Description Is Required !"
                        )
                      }
                    />
                  </div>
                  <div className="col-12 devloper-select">
                    <label className="label-input">Select App</label>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="controllable-states-demo"
                      options={appData ? appData : []}
                      size="small"
                      filterSelectedOptions
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "unset !important",
                        },
                      }}
                      onChange={handleChangeSelectApp}
                      getOptionLabel={(option) => option?.appName || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select App"
                        />
                      )}
                    />
                    {error?.immediatelyAppSelect && (
                      <p className="errorMessage">
                        {error?.immediatelyAppSelect}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-12">
                    <Input
                      label={`Image`}
                      id={`link`}
                      type={`text`}
                      name={"link"}
                      value={immediatelyInput.link}
                      errorMessage={
                        error.immediatelyLink && error.immediatelyLink
                      }
                      onChange={(e) =>
                        handleImmediatelyInputChange(
                          e,
                          "immediatelyLink",
                          "Image Is Required !"
                        )
                      }
                    />
                  </div>
                  {/* <div className="col-12 col-md-12">
                    {immediatelyInput.link && (
                      <img src={immediatelyInput.link} />
                    )}
                  </div> */}
                  <div className="mt-3 d-flex justify-content-end send-btn">
                    <Button
                      btnIcon={<SendIcon />}
                      type={"button"}
                      style={{ marginRight: "10px" }}
                      onClick={() => handleSubmit()}
                      btnName={"Send"}
                      newClass={"back-btn"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="notification-pageBox">
              <form>
                <h6>Specific Time Notification Update</h6>
                <div className="row">
                  <div className="col-12">
                    <Input
                      label={`Title`}
                      id={`title`}
                      type={`text`}
                      name={"title"}
                      value={specificTimeInput?.title}
                      errorMessage={
                        specificTimeError.specificTimeTitle &&
                        specificTimeError.specificTimeTitle
                      }
                      onChange={(e) =>
                        handleSpecificTimeChange(
                          e,
                          "specificTimeTitle",
                          "Image Is Required !"
                        )
                      }
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={`Description`}
                      id={`description`}
                      type={`text`}
                      name={"description"}
                      value={specificTimeInput?.description}
                      errorMessage={
                        specificTimeError.specificTimeDescription &&
                        specificTimeError.specificTimeDescription
                      }
                      onChange={(e) =>
                        handleSpecificTimeChange(
                          e,
                          "specificTimeDescription",
                          "Description Is Required !"
                        )
                      }
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={`Image`}
                      id={`link`}
                      type={`text`}
                      name={"link"}
                      value={specificTimeInput.link}
                      errorMessage={
                        specificTimeError.specificTimeLink &&
                        specificTimeError.specificTimeLink
                      }
                      onChange={(e) =>
                        handleSpecificTimeChange(
                          e,
                          "specificTimeLink",
                          "Image Is Required !"
                        )
                      }
                    />
                    {/* {specificTimeInput.link &&
                      specificTimeInput.link?.map((item) => {
                        return <img src={item} />;
                      })} */}
                  </div>
                  <div className="mt-3 d-flex justify-content-end send-btn">
                    <Button
                      btnIcon={<UpdateIcon />}
                      type={"button"}
                      style={{ marginRight: "10px" }}
                      onClick={() => handleSubmitpecificTime()}
                      btnName={"Update"}
                      newClass={"back-btn"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="notification-pageBox">
              <form className="w-100">
                <h6>Specific Time Send Notification </h6>
                <div className="row w-100">
                  <div className="col-12 devloper-select select-app">
                    <label className="label-input">Select App</label>
                    <Autocomplete
                      value={valueSendNoti}
                      multiple
                      limitTags={1}
                      size="small"
                      getOptionLabel={(option) => option?.appName || ""}
                      filterSelectedOptions
                      onChange={(event, newValue) => {
                        setValueSendNoti(newValue);
                        if (!newValue) {
                          return setError({
                            ...error,
                            specificNotiApp: `Select App Is Required !`,
                          });
                        } else {
                          return setError({
                            ...error,
                            specificNotiApp: "",
                          });
                        }
                      }}
                      inputValue={inputValueSendNoti}
                      onInputChange={(event, newInputValue) => {
                        setInputValueSendNoti(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={appData ? appData : []}
                      renderInput={(params) => (
                        <TextField {...params} label="Select App" />
                      )}
                    />
                    {specificNotiError?.specificNotiApp && (
                      <p className="errorMessage">
                        {specificNotiError?.specificNotiApp}
                      </p>
                    )}
                  </div>
                  <div className="col-12">
                    <Input
                      label={`Time`}
                      id={`number`}
                      type={`number`}
                      name={"timeSendNoti"}
                      value={timeSendNoti}
                      errorMessage={
                        specificNotiError.specificNotiTime &&
                        specificNotiError.specificNotiTime
                      }
                      onChange={(e) => {
                        setTimeSendNoti(e.target.value);
                        if (!e.target.value) {
                          return setSpecificNotiError({
                            ...error,
                            specificNotiTime: `Time Is Required !`,
                          });
                        } else {
                          return setSpecificNotiError({
                            ...error,
                            specificNotiTime: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mt-3 d-flex justify-content-end send-btn">
                    <Button
                      btnIcon={<SendIcon />}
                      type={"button"}
                      style={{ marginRight: "10px" }}
                      onClick={() => handleSpecificNotification()}
                      btnName={"Send"}
                      newClass={"back-btn"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getApp,
  sendNotification,
  getNotificationData,
  editNotification,
})(NotificationPage);
