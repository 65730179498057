import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
    monetization: [],
    isLoading: false,
    isSkeleton: false,
    total: null
};



export const getPlatform = createAsyncThunk(`admin/appMonetization/getPlatform`, async (payload) => {
    return apiInstanceFetch.get(
        `admin/appMonetization/getPlatform?appId=${payload}`
    );
});

export const createPlatform = createAsyncThunk(`admin/appMonetization/createPlatform`, async (payload) => {
    return apiInstanceFetch.post(
        `admin/appMonetization/createPlatform?appId=${payload.appId}`,payload.data
    );
});

export const updatePlatform = createAsyncThunk(`admin/appMonetization/updatePlatform`, async (payload) => {
    return apiInstanceFetch.patch(
        `admin/appMonetization/updatePlatform?appId=${payload.appId}&plateformId=${payload.platformId}`, payload.data
    );
});


export const deletePlatform = createAsyncThunk(`admin/appMonetization/deletePlatform`, async (payload) => {
    return apiInstanceFetch.delete(
        `admin/appMonetization/deletePlatform?appId=${payload?.appId}&plateformId=${payload?.platformId}`,
    );
});

export const plateformShuffle = createAsyncThunk(
    `admin/appMonetization/plateformShuffle`,
    async (payload) => {
      return apiInstanceFetch.patch(`admin/appMonetization/plateformShuffle`,payload);
    }
  );


const newMonetizationSlice = createSlice({
    name: "newMonetization",
    initialState,
    reducers: {},

    extraReducers: (builder) => {

        builder.addCase(plateformShuffle.pending, (state, action) => {
            state.isLoading = true;
          });
      
      
          builder.addCase(plateformShuffle.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload.status === true) {
              state.monetization = action.payload.platforms;
              setToast("success", "Platform Shuffled Successfully");
            }else{
                setToast("error", action.payload.message);
            }

          });
      
          builder.addCase(plateformShuffle.rejected, (state, action) => {
            state.isLoading = false;
            setToast("error", action.payload.message);
          });
      

        builder.addCase(getPlatform.pending, (state, action) => {
            state.isSkeleton = true;
        });

        builder.addCase(getPlatform.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.monetization = action?.payload?.data;
        });

        builder.addCase(getPlatform.rejected, (state, action) => {
            state.isSkeleton = false;
        });

        builder.addCase(createPlatform.pending, (state, action) => {
            state.isSkeleton = true;
        });

        builder.addCase(createPlatform.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.monetization = action?.payload?.data;
        });

        builder.addCase(createPlatform.rejected, (state, action) => {
            state.isSkeleton = false;
        });


        builder.addCase(updatePlatform.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(updatePlatform.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action?.payload?.status === true) {
                state.monetization = action.payload?.data;
                setToast("success", "Data Updated Successfully");
            }
        });

        builder.addCase(updatePlatform.rejected, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(deletePlatform.pending, (state, action) => {
            state.isSkeleton = true;
        });

        builder.addCase(deletePlatform.fulfilled, (state, action) => {
            state.isSkeleton = false;
            if (action.payload.status === true) {
                
                state.monetization = state.monetization.filter((data) => {
                    
                    return data._id !== action.meta.arg?.platformId;
                });
                setToast("success", "Deleted SuccessFull");
            } else {
                setToast("error", action.payload.message);
            }
        });

        builder.addCase(deletePlatform.rejected, (state, action) => {
            state.isSkeleton = false;
        });

    },
});

export default newMonetizationSlice.reducer