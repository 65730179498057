import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Input from "../extra/Input";
import { randomCommunityMessage } from "../../store/slice/communitySlice";
import { IconButton } from "@mui/material";

const CommunitySchedule = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState({
    startTime: "",
    endTime: "",
    message: "",
    image: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      
      setStartTime(dialogueData?.startTime);
      setEndTime(dialogueData?.endTime);
      setMessage(dialogueData?.messages.join(","));
      setImage(dialogueData?.images.join(","));
    }
  }, [dialogueData]);

  const handleSubmit = () => {
    if (
      !startTime ||
      !endTime ||
      (!message && !image) ||
      parseInt(startTime) > parseInt(endTime) ||
      startTime < 0 ||
      endTime < 0
    ) {
      let error = {};
      if (!startTime) {
        error.startTime = "Please enter start time";
      }
      if (!endTime) {
        error.endTime = "Please enter end time";
      }
      if (!message && !image) {
        error.message = "Please enter message";
      }

      if (parseInt(startTime) > parseInt(endTime)) {
        error.endTime = "End time should be greater than start time";
      }
      if (startTime < 0 || endTime < 0) {
        error.startTime = "Start time should be greater than 0";
        error.endTime = "End time should be greater than 0";
      }

      return setError({ ...error });
    }

    let data = {
      startTime,
      endTime,
      messages: message,
      images: image,
      communityId: dialogueData?._id,
    };

    dispatch(randomCommunityMessage(data));
    dispatch(closeDialog());
  };
  return (
    <div className="DevDialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>Community Schedule</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row">
              <div className="col-12 col-lg-6">
                <Input
                  label={`Start time`}
                  id={`startTime`}
                  type={`number`}
                  value={startTime}
                  placeholder={"Enter Start time"}
                  errorMessage={error?.startTime && error?.startTime}
                  onChange={(e) => {
                    setStartTime(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-lg-6">
                <Input
                  label={`End time`}
                  id={`endTime`}
                  type={`number`}
                  value={endTime}
                  placeholder={"Enter end time"}
                  errorMessage={error?.endTime && error?.endTime}
                  onChange={(e) => {
                    setEndTime(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Image Url`}
                  id={`endTime`}
                  type={`text`}
                  value={image}
                  placeholder={"Enter Image Url"}
                  errorMessage={error?.image && error?.image}
                  onChange={(e) => {
                    setImage(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Message`}
                  id={`message`}
                  type={`text`}
                  value={message}
                  placeholder={"Enter Message"}
                  errorMessage={error?.message && error?.message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <p className="text-danger my-2">Image and messages are saperate by ( , ) Comma.</p>
          <div className="row dialog-footer mt-3">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={() => dispatch(closeDialog())}
                style={{ marginRight: "10px" }}
                newClass="cancel-btn"
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass="submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunitySchedule;
