import React, { useEffect, useState } from "react";
import {
  getVipPlan,
  planUpdate,
  deleteVipPlan,
  isActiveVipPlan,
} from "../../../store/slice/appSlice";
import { getPlans } from "../../../store/slice/callPlanSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ToggleSwitch from "../../extra/ToggleSwitch";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useLocation } from "react-router-dom";
import { warning } from "../../utils/Alert";
import Button from "../../extra/Button";
import { openDialog } from "../../../store/slice/dialogueSlice";
import PlanEdit from "../../Page/EditApp/PlanEdit";
import { baseURL } from "../../utils/config";
import CallPlanDialog from "../../dialogComponent/CallPlanDialog";

const CallPlan = (props) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isFree, setIsFree] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { vipPlanData, totalVipPlan } = useSelector((state) => state.app);

  const { callPlan } = useSelector((state) => state.callPlan);
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  useEffect(() => {
    const payload = {
      appId: location?.state?.editAppData?._id,
    };
    dispatch(getPlans(location?.state?.editAppData?._id));
  }, [start, limit]);

  useEffect(() => {
    setData(callPlan);
  }, [callPlan]);

  const handleIsfreeSwitch = (row) => {};

  const handleEditPlan = (row, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: row,
    };
    dispatch(openDialog(payload));
  };

  const handleDeltePlan = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            props.deleteVipPlan(row);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleInActiveSwitch = (row) => {
    const payload = {
      id: row?._id,
      value: row?.isActive,
    };
    props.isActiveVipPlan(payload);
  };

  const handlePaymentSwitch = (row, type) => {
    const payload = {
      planId: row?._id,
      appId: location?.state?.editAppData?._id,
      type: type,
      value: row?.isActive,
    };
    props.paymentPlanSwitch(payload);
  };
  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Min Calls",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.minCalls ? row?.minCalls : "-"}
        </span>
      ),
    },
    {
      Header: "Max Calls",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.maxCalls ? row?.maxCalls : "-"}
        </span>
      ),
    },
    {
      Header: "Rate Per Call",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.ratePerCall ? row?.ratePerCall : "-"}</span>
      ),
    },
   
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"editBtn"}
          onClick={() => handleEditPlan(row, "editPlan")}
        />
      ),
    },
    
  ];

  return (
    <div>
      {dialogueType === "editPlan" || dialogueType === "newPlan" ? (
        <CallPlanDialog />
      ) : (
        <>
          <div className="plan-table">
            <div className="new-button d-flex justify-content-end">
              <Button
                btnName={"New"}
                btnIcon={<AddRoundedIcon />}
                type={"button"}
                onClick={() => handleNewOpenDialog("newPlan")}
                newClass={"new-btn"}
              />
            </div>
            <Table
              data={data}
              type={"server"}
              mapData={mapData}
              start={start}
              limit={limit}
            />
            <Pagination
              type={"server"}
              start={start}
              limit={limit}
              totalData={totalVipPlan}
              setStart={setStart}
              setLimit={setLimit}
              handleRowsPerPage={handleRowsPerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default connect(null, {
  getVipPlan,
  planUpdate,
  deleteVipPlan,
  isActiveVipPlan,
})(CallPlan);
