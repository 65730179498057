/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { warning } from "../utils/Alert";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { openDialog } from "../../store/slice/dialogueSlice";
import  MessageIcon  from '@mui/icons-material/Message';

import { getCommunity, deleteCommunity } from "../../store/slice/communitySlice";
import DevDialog from "../dialogComponent/DevDialog";
import CommunityDialog from "../dialogComponent/CommunityDialog";
import CommunityMessage from "../dialogComponent/CommunityMessage";
import CommunitySchedule from "../dialogComponent/CommunitySchedule.js";

const Community = (props) => {
  const { community,total } = useSelector((state) => state?.community);


  

  console.log(community);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };
    dispatch(getCommunity(payload));
  }, [start, limit]);

  useEffect(() => {
    setData(community);
  }, [community]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.title}</span>,
    },
    {
      Header: "Fake Users",
      body: "fakeUsers",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
             {row?.fakeUserNames?.join(",")}
        </span>
      ),
    },
    {
      Header: "Selected Apps",
      body: "selectedApps",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
            <ShowMoreText text={row?.selectedApps.map(app => app?.appName).join(",")} limit={30} />

        </span>
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD/MM/YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Message Schedule",
      body: "message",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(openDialog({ dialogueType: "schedule", dialogueData: row }))
          }
          btnIcon={<MessageIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Message",
      body: "message",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(openDialog({ dialogueType: "message", dialogueData: row }))
          }
          btnIcon={<MessageIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(openDialog({ dialogueType: "community", dialogueData: row }))
          }
          btnIcon={<EditRoundedIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass="deleteBtn"
          onClick={() => handleDeleteCommunity(row?._id)}
        />
      ),
    },
  ];

  const handleDeleteCommunity = (id) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {

            dispatch(deleteCommunity(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="mainCustomAd">
      <Title title="Community" />
      {dialogue && dialogueType === "community" && <CommunityDialog />}
      {dialogue && dialogueType === "message" && <CommunityMessage />}
      {dialogue && dialogueType === "schedule" && <CommunitySchedule />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total Community: {data?.length}</h6>
        <div className="row mt-3 pt-1">

          <div className=" new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("community")}
              newClass={"add-btn"}
            />
          </div>
        </div>
        <Table
        data={data}
        type={"server"}
        mapData={mapData}
        start={start}
        limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
       
      </div>
    </div>
  );
};
export default Community


const ShowMoreText = ({ text, limit = 30 }) => {
  const [showMore, setShowMore] = useState(false);

  if (text?.length <= limit) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {showMore ? text : `${text?.slice(0, limit)}...`}
      <button
        onClick={() => setShowMore(!showMore)}
        style={{ marginLeft: '5px', border: 'none', background: 'none', color: 'blue', cursor: 'pointer' }}
      >
        {showMore ? 'Show less' : 'Show more'}
      </button>
    </span>
  );
};