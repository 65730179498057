import React, { useEffect, useState } from "react";

import { deleteBanner, getBanners, handleActive } from "../../store/slice/bannerSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useLocation } from "react-router-dom";
import { warning } from "../utils/Alert";
import Button from "../extra/Button";
import EdiText from "react-editext";

import BannerDialog from "../dialogComponent/BannerDialog";
import { openDialog } from "../../store/slice/dialogueSlice";
import { editApp, getTestApp } from "../../store/slice/TestSlice";
import AddRoundedIcon from "@mui/icons-material/AddRounded";


const Tester = () => {
  const [data, setData] = useState([]);
  const [editRowId, setEditRowId] = useState(null); // To track which row is being edited
  const [newDeviceName, setNewDeviceName] = useState(""); // To track the new device nam

  const location = useLocation();
  const dispatch = useDispatch();
  const { testApp } = useSelector((state) => state.test);
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );


  useEffect(() => {
      dispatch(getTestApp(location.state?.editAppData?._id));
  }, [location.state,dispatch]);

  useEffect(() => {
    setData(testApp);
  }, [testApp]);

  const handleEditClick = (row) => {
    setEditRowId(row._id); // Track the row being edited
    setNewDeviceName(row.deviceName); // Initialize with the current device name
  };

  // Handle save for the new device name
  const handleSaveClick = (row) => {
    const payload = {
      id: row._id,
      deviceName: newDeviceName,
    }
    dispatch(editApp(payload))
  };


  const handleEditPlan = (row, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: row,
    };
    dispatch(openDialog(payload));
  };

  const handleDeletePlan = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
              dispatch(deleteBanner(row));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleInActiveSwitch = (row) => {

      dispatch(handleActive(row?._id));
  };

  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => <span className="  text-nowrap">{index + 1}</span>,
    },

   
    {
      Header: "Device Name",
      body: "domainName",
      Cell: ({ row, index }) => (
        <div className="edit-text-input">
          <EdiText
            type="text"
            value={row?.deviceName || ""}
            onSave={(val) => dispatch(editApp({deviceName:val, historyId:row?._id,appId:location.state?.editAppData?._id}))}
          />
        </div>
      ),
    },
    {
      Header: "User",
      body: "user",
      Cell: ({ row, index }) => (
        <div className="edit-text-input">
          <EdiText
            type="text"
            value={row?.user || ""}
            onSave={(val) => dispatch(editApp({user:val, historyId:row?._id,appId:location.state?.editAppData?._id}))}
          />
        </div>
      ),
    },
    {
      Header: "Identity",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.identity || "-"}</span>
      ),
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        // Helper function to get last 5 days in MM/DD format
        const getLast5Days = () => {
          const days = [];
          const currentDate = new Date();
    
          for (let i = 0; i < 5; i++) {
            const tempDate = new Date(currentDate);
            tempDate.setDate(currentDate.getDate() - i); // Get today and last 4 days
            const formattedDate = tempDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }); // Format as MM/DD
            days.push(formattedDate);
          }
          return days.reverse(); // Reverse to show in ascending order
        };
    
        // Get the last 5 days
        const last5Days = getLast5Days();
    
        // Function to check if a date exists in the row's date array, comparing only MM/DD
        const isDateChecked = (dateToCheck) => {
          // Loop through the row.date array and compare only the date part (MM/DD)
          return row?.date?.some((fullDate) => {
            const datePart = fullDate.split(",")[0]; // Get only the date part (MM/DD/YYYY)
            const formattedDatePart = new Date(datePart).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }); // Convert to MM/DD
            return formattedDatePart === dateToCheck;
          });
        };
    
        return (
          <div className="scrollable-container">
            {/* First line: Show the last 5 days (MM/DD) */}
            <div className="date-row" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              {last5Days.map((day, index) => (
                <div key={index} style={{ textAlign: 'center', flex: 1 }}>
                  {day}
                </div>
              ))}
            </div>
    
            {/* Second line: Checkboxes for the last 5 days */}
            <div className="checkbox-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
              {last5Days.map((day, index) => (
                <div key={index} style={{ textAlign: 'center', flex: 1 ,fontSize:'8px'}}>
                  <input
                    type="checkbox"
                    checked={isDateChecked(day)}
                    readOnly
                  />
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    
    {
      Header: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.createdAt.split("T")[0]}</span>
      ),
    },
  ];

  return (
    <div>

        <>
          <div className="plan-table testApp">

            <Table data={data} mapData={mapData} type={"server"} />
          </div>
        </>
    </div>
  );
};
export default Tester;
