import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  testApp: [],
  total: null,
  isLoading: false,
  isSkeleton: false,
};

export const getTestApp = createAsyncThunk("admin/appTesting/fetchTestingHistory", async (payload) => {
  return apiInstanceFetch.get(
    `admin/appTesting/fetchTestingHistory?appId=${payload}`,
    payload
  );
});

export const editApp = createAsyncThunk(
  "admin/appTesting/updateTestingHistory",
  async (payload) => {
    return apiInstance.patch("admin/appTesting/updateTestingHistory", payload);
  }
);


const testSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestApp.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getTestApp.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.testApp = action.payload.data;
    });
    builder.addCase(getTestApp.rejected, (state, action) => {
      state.isSkeleton = false;
    });

   

    builder.addCase(editApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testApp = state?.testApp?.map((app) => {
        
        if (app?._id === action.meta.arg?.historyId) {
          return action.payload?.data;
        }
        return app;
      });
      setToast("success", "App Updated Successfully");
    });
    builder.addCase(editApp.rejected, (state, action) => {
      state.isLoading = false;
    });

  },
});
export default testSlice.reducer;
