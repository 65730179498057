import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
import axios from "axios";
const initialState = {
  community: [],
  total: null,
  isSkeleton: false,
  isLoading: false,
};

export const getCommunity = createAsyncThunk(
  "admin/community/getCommunity",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/community/getCommunity?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const communityMessage = createAsyncThunk(
  "admin/community/messageByAdmin",
  async (payload) => {
    
    return axios.post(
      `admin/community/messageByAdmin`,payload
    );
  }
);

export const addCommunity = createAsyncThunk(
  "admin/community/storeCommunity",
  async (payload) => {
    return apiInstance.post(`admin/community/storeCommunity`, payload);
  }
);

export const randomCommunityMessage = createAsyncThunk(
  "admin/community/randomMessageSetByAdmin",
  async (payload) => {
    return apiInstance.post(`admin/community/randomMessageSetByAdmin`, payload);
  }
);

export const updateCommunity = createAsyncThunk(
  "admin/community/updateCommunity",
  async (payload) => {
    return apiInstance.patch(`admin/Community/updateCommunity`, payload);
  }
);

export const handleActive = createAsyncThunk(
  "admin/community/isActive",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/community/isActive?bannerId=${payload}`
    );
  }
);

export const deleteCommunity = createAsyncThunk(
  "admin/community/deleteCommunity",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/community/deleteCommunity?communityId=${payload}`
    );
  }
);
const communitySlice = createSlice({
  name: "communitySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommunity.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getCommunity.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.community = action.payload.data;
      state.total = action.payload.total;
    });

    builder.addCase(getCommunity.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addCommunity.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(addCommunity.fulfilled, (state, action) => {
      
      state.isSkeleton = false;
      const variable = {
        ...action?.payload?.community,
      };
      state.community.unshift(variable);
    });

    builder.addCase(addCommunity.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updateCommunity.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCommunity.fulfilled, (state, action) => {
      const updatedCommunity = action?.payload?.data;
      const communityIndex = state?.community?.findIndex(
        (community) => community?._id === updatedCommunity?._id
      );
      if (communityIndex !== -1) {
        state.community[communityIndex] = {
          ...state.community[communityIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",
        "community Update  Successfully"
      );
    });

    builder.addCase(updateCommunity.rejected, (state, action) => {
      state.isLoading = true;
    });


     builder.addCase(randomCommunityMessage.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(randomCommunityMessage.fulfilled, (state, action) => {
      const updatedCommunity = action?.payload?.data;
      const communityIndex = state?.community?.findIndex(
        (community) => community?._id === updatedCommunity?._id
      );
      if (communityIndex !== -1) {
        state.community[communityIndex] = {
          ...state.community[communityIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",
        "community Update  Successfully"
      );
    });

    builder.addCase(randomCommunityMessage.rejected, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(handleActive.fulfilled, (state, action) => {
      const updatedCommunity = action?.payload?.data;
      const bannerIndex = state?.community?.findIndex(
        (community) => community?._id === updatedCommunity?._id
      );
      if (bannerIndex !== -1) {
        state.community[bannerIndex] = {
          ...state.community[bannerIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",

        "community Update  Successfully"
      );
    });
    builder.addCase(handleActive.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCommunity.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteCommunity.fulfilled, (state, action) => {
      state.community = state.community.filter(
        (community) => community._id !== action.meta.arg
      );
      state.isLoading = false;
      setToast("success", "community Deleted Successfully");
    });
    builder.addCase(deleteCommunity.rejected, (state, action) => {
      state.isLoading = false;
    });


    builder.addCase(communityMessage.pending, (state, action) => {
    });

    builder.addCase(communityMessage.fulfilled, (state, action) => {
      if(action.payload.data.status === true) {
        
        
        setToast("success", "Sent message to community Successfully");
      }

    });
    builder.addCase(communityMessage.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default communitySlice.reducer;
