import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import TextArea from "../extra/TextArea";
import {
  getFakeMessage,
  updatedFakeMessage,
} from "../../store/slice/hostSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";

const ScreenDialog = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );


  

  const [message, setMessage] = useState([]);

  useEffect(() => {
    setMessage(dialogueData?.atTotalScreen.join("\n"));
  }, [dialogueData]);
  
  

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <div className="gameDialogue fake-messageDialog">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>All Screen</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="col-12">
                  <div className="input-post">
                    <TextArea
                 
                      row={20}
                      value={message}
                      name={"Message"}
                    />
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenDialog
