import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
const initialState = {
  callPlan: [],
  isSkeleton: false,
  isLoading: false,
};

export const getPlans = createAsyncThunk(
  "admin/callplan/retriveCallplan",
  async (payload) => {
    return apiInstanceFetch.get(`admin/callplan/retriveCallplan?appId=${payload}`);
  }
);

export const addPlan = createAsyncThunk(
  "admin/callplan/callplanCreate",
  async (payload) => {
    return apiInstance.post(`admin/callplan/callplanCreate`, payload);
  }
);

export const updatePlan = createAsyncThunk(
  "admin/callplan/callplanUpdate",
  async (payload) => {
    return apiInstance.patch(
      `admin/callplan/callplanUpdate?callplanId=${payload.callPlanId}&appId=${payload.appId}`,
      payload.data
    );
  }
);

export const handleActive = createAsyncThunk(
  "admin/banner/isActive",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/banner/isActive?bannerId=${payload}`);
  }
);

export const deleteBanner = createAsyncThunk(
  "admin/banner/deleteBanner",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/banner/deleteBanner?bannerId=${payload}`
    );
  }
);
const coinPlanSlice = createSlice({
  name: "coinPlanSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlans.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.callPlan = action.payload.data;
    });

    builder.addCase(getPlans.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addPlan.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(addPlan.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.callPlan.unshift(action?.payload?.data);
    });

    builder.addCase(addPlan.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updatePlan.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updatePlan.fulfilled, (state, action) => {
      const updatedPlan = action?.payload?.data;
      const planIndex = state?.callPlan?.findIndex(
        (callPlan) => callPlan?._id === updatedPlan?._id
      );
      if (planIndex !== -1) {
        state.callPlan[planIndex] = {
          ...state.callPlan[planIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",

        "Plan Update  Successfully"
      );
    });

    builder.addCase(updatePlan.rejected, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(handleActive.fulfilled, (state, action) => {
      const updatedBanner = action?.payload?.data;
      const bannerIndex = state?.banner?.findIndex(
        (banner) => banner?._id === updatedBanner?._id
      );
      if (bannerIndex !== -1) {
        state.banner[bannerIndex] = {
          ...state.banner[bannerIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",

        "Banner Update  Successfully"
      );
    });
    builder.addCase(handleActive.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteBanner.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.banner = state.banner.filter((banner) => banner._id !== action.meta.arg);
      state.isLoading = false;
      setToast("success", "Banner Deleted Successfully");
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default coinPlanSlice.reducer;
