import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getPlatform,
  deletePlatform,
  plateformShuffle,

} from "../../../store/slice/newMonetizationSlice";
import MonetizationBox from "../../extra/MonetizationBox";
import Button from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { openDialog } from "../../../store/slice/dialogueSlice";
import AddMonetization from "../../dialogComponent/AddMonetization";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { warning } from "../../utils/Alert";
import {
  appMonetizationSwitch,
  getMonetization,
} from "../../../store/slice/appSlice";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

const Monetization2 = () => {
  const location = useLocation();
  const { monetization } = useSelector((state) => state.monetization);
  const { monetizationData: appMonetization } = useSelector(
    (state) => state.app
  );
  const { dialogueType } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [monetizationData, setMonetizationData] = useState([]);
  const [isAppActive, setIsAppActive] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newServices, setNewServices] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const payload = location?.state?.editAppData?._id;
    dispatch(getPlatform(payload));
    dispatch(getMonetization(payload));
  }, [location.state, dispatch]);

  const mainAppId = location?.state?.editAppData?._id;

  const defaultOptions = monetization?.map((option) => ({
    value: option?._id,
    label: option?.name,
  }));

  useEffect(() => {
    setMonetizationData(monetization);
    setSelectedOptions(monetization);
    setNewServices(defaultOptions)
    setError("")
  }, [monetization]);



  console.log("newServices: ", newServices);

  const handleInputChange = (index, newValue) => {
    setMonetizationData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        ...newValue,
      };
      console.log("Updated monetizationData:", newData); // Debug log
      return newData;
    });
  };

  const handleDelete = (platformId, appId) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          let data = { appId, platformId };
          dispatch(deletePlatform(data));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type, appId) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: appId,
    };
    dispatch(openDialog(payload));
  };

  const handleUpdate = (type, data, appId, platformId) => {
    const payloadData = {
      data,
      appId,
      platformId,
    };
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: payloadData,
    };
    dispatch(openDialog(payload));
  };

  const handleSubmit = (e) => {
    if(newServices.length !== monetizationData.length){
       return setError("Please select all services");
    }

    const plateformIds = newServices.map((option) => option?._id).join(",");

    const payload = {
      plateformIds: plateformIds,
      appId: mainAppId,
    };
    dispatch(plateformShuffle(payload));
    setError("")
  };

  const handleSwitch = (id, type, value) => {
    const payload = {
      appId: id,
      type: type,
      value: value,
    };
    dispatch(appMonetizationSwitch(payload));
  };

  function onSelect(selectedList, selectedItem) {
    setNewServices(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    const updatedServices = selectedList?.filter(
      (item) => item.id !== removedItem.id
    );
    setNewServices(updatedServices);
  }

  const options = monetizationData?.map((option) => ({
    name: option?.name,
    _id: option?._id,
  }));

  return (
    <div className="monetization-edit">
      {(dialogueType === "addMonetization" ||
        dialogueType === "editMonetization") && <AddMonetization />}
      <div className="d-flex justify-content-start  new-button me-auto">
        <div className="row my-2">
          <div className="col-10">
            <Multiselect
              options={options}
              selectedValues={selectedOptions ? selectedOptions : []}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              className="cursor-pointer"
            />
          </div>
      <Button
        btnName={"Submit"}
        type={"button"}
        onClick={() => handleSubmit()}
        newClass={"new-btn mb-3 m-0 "}
        parentClass={"mt-1 mb-2 col-2"}
      />
        </div>
      </div>
        {error && <p className="text-danger">{error}</p>}
      <div className="d-flex ">
        <Button
          btnName={"New"}
   
          btnIcon={<AddRoundedIcon />}
          parentClass={"my-auto"}
          type={"button"}
          onClick={() =>
            handleNewOpenDialog(
              "addMonetization",
              location.state.editAppData?._id
            )
          }
          newClass={"new-btn align-self-center "}
        />
        <div className="ms-5">
          <label className="label-text mb-2 me-3 label">App Active</label>
          <ToggleSwitch
            onChange={setIsAppActive}
            value={appMonetization.isAppActive}
            onClick={() =>
              handleSwitch(location.state.editAppData?._id, "isAppActive")
            }
          />
        </div>
        <div className=" ms-2 ">
          <label className="label-text mb-2 label">IsShuffle</label>
          <ToggleSwitch
            value={appMonetization.isShuffle}
            onChange={setIsShuffle}
            onClick={() =>
              handleSwitch(location.state.editAppData?._id, "isShuffle")
            }
          />
        </div>
      </div>
      <form>
        <div className="add-revenue">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="row" style={{ position: "relative" }}>
                {monetizationData.map((item, index) => (
                  <div
                    className="col-12 mb-4 pb-4 col-md-6 mb-md-0 pb-md-0"
                    key={index}
                  >
                    <MonetizationBox
                      title={item.name}
                      inputValue={item}
                      setInputValue={(newValue) =>
                        handleInputChange(index, newValue)
                      }
                      margin={"0px 10px 0px 0px"}
                      marginShow={true}
                      onDelete={() => handleDelete(item?._id, mainAppId)}
                      onUpdate={() =>
                        handleUpdate(
                          "editMonetization",
                          item,
                          mainAppId,
                          item?._id
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Monetization2;
