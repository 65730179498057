import React, { useEffect, useState, useRef } from "react";
import { getHostTwo, deleteHost } from "../../store/slice/hostSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { warning } from "../utils/Alert";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { openDialog } from "../../store/slice/dialogueSlice";
import ToggleSwitch from "../extra/ToggleSwitch";
import { useNavigate } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import HostDialog from "../dialogComponent/HostDialog";
import FakeMessageDialog from "../dialogComponent/FakeMessageDialog";
import FakeCommentDialog from "../dialogComponent/FakeCommentDialog";
import HostDialogTwo from "../dialogComponent/HostDialogTwo";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const HostTableTwo = (props) => {
  const { hostData, hostTotal } = useSelector((state) => state.hostData);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <div className="image-showTable">
          {row?.image?.map((item) => {
            return (
              <img src={item} alt="host" />
            );
          })}
        </div>
      ),
    },
    {
      Header: "Video",
      body: "video",
      Cell: ({ row }) => {
        const videoRef = useRef(null);
        const [isPlaying, setIsPlaying] = useState(false);
    
        const handleVideoClick = () => {
          if (isPlaying) {
            videoRef.current.pause(); // Pause if currently playing
          } else {
            videoRef.current.play(); // Play if currently paused
          }
          setIsPlaying(!isPlaying); // Toggle the isPlaying state
        };
    
        return (
          <video
            ref={videoRef}
            height="150"
            width="150"
            src={row?.video}
            style={{ borderRadius: "10px", cursor: "pointer" }}
            onClick={handleVideoClick} // Toggle play/pause on click
          />
        );
      },
    },
    
    
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.name}</span>,
    },
    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => (
        <>
          {row?.gender === "male" ? (
            <Button
              btnName={"Male"}
              style={{ marginRight: "10px" }}
              newClass={`male-btn`}
            />
          ) : row?.gender === "female" ? (
            <Button
              btnName={"Female"}
              style={{ marginRight: "10px" }}
              newClass={`female-btn m-auto`}
            />
          ) : (
            <div className="text-center">-</div>
          )}
        </>
      ),
    },
    {
      Header: "Bio",
      body: "bio",
      Cell: ({ row }) => <p className="host-bio-text">{row?.bio}</p>,
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(
              openDialog({ dialogueType: "editHost", dialogueData: row })
            )
          }
          btnIcon={<EditRoundedIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass="deleteBtn"
          onClick={() => handleDeleteApp(row?._id)}
        />
      ),
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  const handleDeleteApp = (id) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            dispatch(deleteHost(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };
    dispatch(getHostTwo(payload));
  }, [start, limit]);

  useEffect(() => {
    setData(hostData);
  }, [hostData]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };


  return (
    <div className="mainCustomAd hostTable">
      <Title title="Host Table" />
      {(dialogueType === "createHost" || dialogueType === "editHost") && (
        <HostDialogTwo />
      )}
      {dialogueType === "messageDialog" && <FakeMessageDialog />}
      {dialogueType === "commentDialog" && <FakeCommentDialog />}
      <div className="customAdTable">
        <div className="row">
          <div className="col-12 col-sm-2">
            <h6 style={{ marginTop: "10px" }}>Total Host: {hostTotal}</h6>
          </div>
          <div className="col-12 col-sm-10 d-flex justify-content-end">
            <Button
              btnName={"M 1"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 1,genderType:1 },
                  })
                )
              }
              newClass={"add-btn fake-messageBtn"}
            />
            <Button
              btnName={"M 2"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 2,genderType:1 },
                  })
                )
              }
              newClass={"add-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"M 3"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 3,genderType:1 },
                  })
                )
              }
              newClass={"add-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"M 4"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 4,genderType:1 },
                  })
                )
              }
              newClass={"add-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"F 1"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 5,genderType:2 },
                  })
                )
              }
              newClass={"female-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"F 2"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 6,genderType:2 },
                  })
                )
              }
              newClass={"female-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"F 3"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 7,genderType:2 },
                  })
                )
              }
              newClass={"female-btn fake-messageBtn ms-2"}
            />
            <Button
              btnName={"F 4"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 8,genderType:2 },
                  })
                )
              }
              newClass={"female-btn fake-messageBtn ms-2"}
            />
          </div>
        </div>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={hostData}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("createHost")}
              newClass={"add-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={hostTotal}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, { getHostTwo, deleteHost })(HostTableTwo);
